<template>
  <div class="downloadReport" id="pdfDom">
      <div class="page1">
          <div class="title">
              <div class="left">{{byOrgName}}脊柱筛查评估团体报告</div>
              <img alt="logo" class="logo" :src="rlogo">
          </div>
          <div class="baseInfo">
              <span class="top2">基本信息</span>
              <table border="1" style="width:100%;margin-top:20px;" cellspacing="0" cellpadding="24">
                  <tr class="tbfontt">
                      <td colspan="2" style="width:50%">筛查机构：{{orgName}}</td>
                      <td colspan="2" style="width:50%">筛查地区：{{orgCity}}</td>
                     
                  </tr>
                  <tr class="tbfontt">
                      <td colspan="2">受检组织名称：{{byOrgName}}</td>
                      <td colspan="2">受检组织编号：{{byOrgNo}}</td>
                  </tr>
                  <tr class="tbfontt">
                      <td style="width:25%">筛查总人数：{{manNormal+manAnomalous+manSeemingly+manExamineException+womanNormal+womanAnomalous+womanSeemingly+womanExamineException}}人</td>
                      <td style="width:25%">男生：{{manNormal+manAnomalous+manSeemingly+manExamineException}}人</td>
                      <td style="width:25%">女生：{{womanNormal+womanAnomalous+womanSeemingly+womanExamineException}}人</td>
                      <td style="width:25%"></td>
                  </tr>
                  <tr class="tbfontt">
                      <td>正常人数：{{manNormal+womanNormal}}人</td>
                      <td>姿态异常人数：{{manAnomalous+womanAnomalous}}人</td>
                      <td>疑似脊柱侧弯人数：{{manSeemingly+womanSeemingly}}人</td>
                      <td>检测异常人数：{{manExamineException+womanExamineException}}人</td>
                  </tr>
              </table>
          </div>
          <!-- chart -->
          <div class="bar" ref="bar"></div>
          <!-- chartTablr -->
          <table border="1" style="width:100%;margin-top:150px" cellspacing="0" cellpadding="24">
              <tr class="tbfont"> 
                  <td style="width:20%"></td>
                  <td style="width:20%">正常</td>
                  <td style="width:20%">姿态异常</td>
                  <td style="width:20%">疑似脊柱侧弯</td>
                  <td style="width:20%">检测异常</td>
              </tr>
              <tr class="tbfont">
                  <td>人数</td>
                  <td>{{manNormal+womanNormal}}</td>
                  <td>{{manAnomalous+womanAnomalous}}</td>
                  <td>{{manSeemingly+womanSeemingly}}</td>
                  <td>{{manExamineException+womanExamineException}}</td>
              </tr>
              <tr class="tbfont">
                  <td>占比</td>
                  <td>{{nomalPer}}%</td>
                  <td>{{anomalousPer}}%</td>
                  <td>{{seemingly}}%</td>
                  <td>{{erroPer}}%</td>
              </tr>
          </table>
          <!-- footerbox -->
          <div class="footer">
              <span class="tex">零动医疗科技 www.zerodrbody.com</span>
              <div class="wxlogo">
                  <div class="outBox" style="margin-right:68px">
                      <img alt="" class="lox" :src="lox">
                      <p class="pxc">DrBody零动健康公众号</p>
                  </div>
                  <div class="outBox">
                      <img alt="" class="lox" :src="qux">
                      <p class="pxc">脊柱小助手@咨询</p>
                  </div>
              </div>
          </div>
      </div>
      <!-- page2 -->
      <div class="page2">
          <div class="title">
              <div class="left">{{byOrgName}}脊柱筛查评估团体报告</div>
              <img alt="logo" class="logo" :src="rlogo">
          </div>
          <div class="pie" ref="pie"></div>
          <table border="1" style="width:100%;margin-top:60px" cellspacing="0" cellpadding="20">
              <tr class="tbfont"> 
                  <td style="width:20%"></td>
                  <td style="width:20%">正常</td>
                  <td style="width:20%">姿态异常</td>
                  <td style="width:20%">疑似脊柱侧弯</td>
                  <td style="width:20%">检测异常</td>
              </tr>
              <tr class="tbfont">
                  <td>男</td>
                  <td>{{manNormal}}</td>
                  <td>{{manAnomalous}}</td>
                  <td>{{manSeemingly}}</td>
                  <td>{{manExamineException}}</td>
              </tr>
              <tr class="tbfont">
                  <td>女</td>
                  <td>{{womanNormal}}</td>
                  <td>{{womanAnomalous}}</td>
                  <td>{{womanSeemingly}}</td>
                  <td>{{womanExamineException}}</td>
              </tr>
          </table>
          <!-- 3chart -->
          <div class="thchart">
              <div class="chartBox">
                  <div class="gauge" ref="guage"></div>
                  <p style="font-size:28px;color:#666666;margin:0">姿态异常各类情况占总人数百分比环形图</p>
              </div>
              <div class="tableBox">
                  <table border="1" style="width:100%;margin-top:60px" cellspacing="0" cellpadding="20">
                        <tr class="tbfont"> 
                            <td style="width:20%"></td>
                            <td style="width:40%">姿态异常人数</td>
                            <td style="width:40%">姿态异常占比</td>
                        </tr>
                        <tr class="tbfont">
                            <td>男</td>
                            <td>{{manAnomalous}}</td>
                            <td>{{((manAnomalous/spineTaltol)*100).toFixed(2)}}%</td>
                        </tr>
                        <tr class="tbfont">
                            <td>女</td>
                            <td>{{womanAnomalous}}</td>
                            <td>{{((womanAnomalous/spineTaltol)*100).toFixed(2)}}%</td>
                        </tr>
                        <tr class="tbfont">
                            <td>总人数</td>
                            <td>{{manAnomalous+womanAnomalous}}</td>
                            <td>{{(((manAnomalous+womanAnomalous)/spineTaltol)*100).toFixed(2)}}%</td>
                        </tr>
                    </table>
              </div>
          </div>
          <!-- footer -->
         <div class="footer">
            <span class="tex">零动医疗科技 www.zerodrbody.com</span>
            <div class="wxlogo">
                <div class="outBox" style="margin-right:68px">
                    <img alt="" class="lox" :src="lox">
                    <p class="pxc">DrBody零动健康公众号</p>
                </div>
                <div class="outBox">
                    <img alt="" class="lox" :src="qux">
                    <p class="pxc">脊柱小助手@咨询</p>
                </div>
                </div>
        </div>
      </div>
      <!-- page3 -->
      <div class="page3">
          <div class="title">
              <div class="left">{{byOrgName}}脊柱筛查评估团体报告</div>
              <img alt="logo" class="logo" :src="rlogo">
          </div>
          <div class="thchart">
              <div class="chartBox">
                  <div class="gauge" ref="guageM"></div>
                  <p style="font-size:28px;color:#666666;margin:0">疑似脊柱侧弯各类情况占总人数百分比环形图</p>
              </div>
              <div class="tableBox">
                  <table border="1" style="width:100%;margin-top:60px" cellspacing="0" cellpadding="20">
                        <tr class="tbfont"> 
                            <td style="width:20%"></td>
                            <td style="width:40%">疑似脊柱侧弯人数</td>
                            <td style="width:40%">疑似脊柱侧弯占比</td>
                        </tr>
                        <tr class="tbfont">
                            <td>男</td>
                            <td>{{manSeemingly}}</td>
                            <td>{{(((manSeemingly)/spineTaltol)*100).toFixed(2)}}%</td>
                        </tr>
                        <tr class="tbfont">
                            <td>女</td>
                            <td>{{womanSeemingly}}</td>
                            <td>{{(((womanSeemingly)/spineTaltol)*100).toFixed(2)}}%</td>
                        </tr>
                        <tr class="tbfont">
                            <td>总人数</td>
                            <td>{{manSeemingly+womanSeemingly}}</td>
                            <td>{{(((manSeemingly+womanSeemingly)/spineTaltol)*100).toFixed(2)}}%</td>
                        </tr>
                    </table>
              </div>
          </div>
          <div class="infoBox">
              <dl>
                  <dd style="color:#333333 !important;padding-bottom:50px;font-weight: bold">团体报告总结</dd>
                  <dd>①脊柱筛查正常人数{{manNormal+womanNormal}}（男生：{{manNormal}}，女生：{{womanNormal}}），占总人数百分比{{nomalPer}}%。</dd>
                  <dd>②脊柱筛查姿态异常人数为{{manAnomalous+womanAnomalous}}人（男生：{{manAnomalous}}，女生：{{womanAnomalous}}），占总人数百分比{{anomalousPer}}%。</dd>
                  <dd>③脊柱筛查疑似脊柱侧弯人数为{{manSeemingly+womanSeemingly}}人（男生：{{manSeemingly}}，女生：{{womanSeemingly}}），占总人数百分比{{seemingly}}%。</dd>
                  <dd>④脊柱筛查检测异常人数为{{manExamineException+womanExamineException}}人（男生：{{womanExamineException}}，女生：{{womanExamineException}}），占总人数百分比{{erroPer}}%。</dd>
              </dl>
              <dl style="margin-top:50px">
                  <dd style="color:#333333 !important;padding-bottom:10px;font-weight: bold">建议：</dd>
                  <dd>对于脊柱筛查正常的受检人建议继续保持，坚持锻炼、保持日常生活中正确行为习惯;</dd>
                  <dd>对于脊柱筛查姿志异常的受检人建议定期检查，矫正8常生活的不良姿态，避免日常生活中容易加重的姿态，如:背单肩包、跷二郎腿、长时间站立重心偏一侧等。 再针对性做强化训练，训练之中多采用双侧动作发力如:俯卧撑、深蹲、引体向上，如果有单侧动作两侧一定要均衡训练，如弓步蹲、单手推肩等。同时可在专业人士指导下进行矫正姿态;</dd>
                  <dd>对于脊柱筛查疑似脊柱侧弯的受检人建议组织一起前往专业机构进行进一步评估诊断， 根据专业人员指导进行各类治疗;</dd>
                  <dd style="margin-top:34px">建议受检组织普及脊柱健康知识，加大宣传教育力度，定期进行青少年脊柱侧弯的健康讲座，推广脊柱健康运动操，预防脊柱健康问题。</dd>
              </dl>
          </div>
          <!-- footer -->
          <div class="footer">
            <span class="tex">零动医疗科技 www.zerodrbody.com</span>
            <div class="wxlogo">
                <div class="outBox" style="margin-right:68px">
                    <img alt="" class="lox" :src="lox">
                    <p class="pxc">DrBody零动健康公众号</p>
                </div>
                <div class="outBox">
                    <img alt="" class="lox" :src="qux">
                    <p class="pxc">脊柱小助手@咨询</p>
                </div>
                </div>
         </div>
      </div>
      <!-- 足部检测情况 -->
      <div class="page4">
          <div class="title">
              <div class="left">{{byOrgName}}足部筛查团体报告</div>
              <img alt="logo" class="logo" :src="rlogo">
          </div>
          <div class="baseInfo">
              <span class="top2">基本信息</span>
              <table border="1" style="width:100%;margin-top:20px;" cellspacing="0" cellpadding="24">
                  <tr class="tbfontt">
                      <td colspan="2" style="width:50%">筛查机构：{{orgName}}</td>
                      <td colspan="2" style="width:50%">筛查地区：{{orgCity}}</td>
                     
                  </tr>
                  <tr class="tbfontt">
                      <td colspan="2">受检组织名称：{{byOrgName}}</td>
                      <td colspan="2">受检组织编号：{{byOrgNo}}</td>
                  </tr>
                  <tr class="tbfontt">
                      <td style="width:25%">筛查总人数：{{manNormalfoo+manFlat+manHigh+manCongenital+manOther+womanNormalfoo+womanFlat+womanHigh+womanCongenital+womanOther}}人</td>
                      <td style="width:25%">男生：{{manNormalfoo+manFlat+manHigh+manCongenital+manOther}}人</td>
                      <td style="width:25%">女生：{{womanNormalfoo+womanFlat+womanHigh+womanCongenital+womanOther}}人</td>
                      <td style="width:25%"></td>
                  </tr>
                  <tr class="tbfontt">
                      <td>正常人数：{{manNormalfoo+womanNormalfoo}}人</td>
                      <td>足部异常人数：{{manFlat+manHigh+manCongenital+manOther+womanFlat+womanHigh+womanCongenital+womanOther}}人</td>
                      <td></td>
                      <td></td>
                  </tr>
              </table>
          </div>
         <div class="chartTable4">
            <div class="bar4" ref="barfoo"></div>
            <div class="tablebox4">
            <table border="1" style="width:100%;margin-top:360px" cellspacing="0" cellpadding="20">
                        <tr class="tbfont"> 
                            <td style="width:20%"></td>
                            <td style="width:40%">正常</td>
                            <td style="width:40%">足部异常</td>
                        </tr>
                        <tr class="tbfont">
                            <td>人数</td>
                            <td>{{manNormalfoo+womanNormalfoo}}</td>
                            <td>{{manFlat+manHigh+manCongenital+manOther+womanFlat+womanHigh+womanCongenital+womanOther}}</td>
                        </tr>
                        <tr class="tbfont">
                            <td>占比</td>
                            <td>{{(((manNormalfoo+womanNormalfoo)/(manNormalfoo+manFlat+manHigh+manCongenital+manOther+womanNormalfoo+womanFlat+womanHigh+womanCongenital+womanOther))*100).toFixed(2)}}%</td>
                            <td>{{(((manFlat+manHigh+manCongenital+manOther+womanFlat+womanHigh+womanCongenital+womanOther)/(manNormalfoo+manFlat+manHigh+manCongenital+manOther+womanNormalfoo+womanFlat+womanHigh+womanCongenital+womanOther))*100).toFixed(2)}}%</td>
                        </tr>
            </table>
            </div>
        </div>
        <!-- 足部异常男女评估分析 -->
        <div class="thchartfoo">
              <div class="chartBox">
                  <div class="gauge" ref="guagefoo"></div>
                  <p style="font-size:28px;color:#666666;margin:0">足部异常男女占总人数百分比环形图</p>
              </div>
              <div class="tableBox">
                  <table border="1" style="width:100%;margin-top:60px" cellspacing="0" cellpadding="20">
                        <tr class="tbfont"> 
                            <td style="width:20%"></td>
                            <td style="width:40%">足部异常</td>
                        </tr>
                        <tr class="tbfont">
                            <td>男</td>
                            <td>{{manFlat+manHigh+manCongenital+manOther}}</td>
                        </tr>
                        <tr class="tbfont">
                            <td>女</td>
                            <td>{{womanFlat+womanHigh+womanCongenital+womanOther}}</td>
                        </tr>
                        <tr class="tbfont">
                            <td>总人数</td>
                            <td>{{manFlat+manHigh+manCongenital+manOther+womanFlat+womanHigh+womanCongenital+womanOther}}</td>
                        </tr>
                    </table>
              </div>
        </div>
          <!-- footerbox -->
          <div class="footer" style="margin-top:46px !important">
              <span class="tex">零动医疗科技 www.zerodrbody.com</span>
              <div class="wxlogo">
                  <div class="outBox" style="margin-right:68px">
                      <img alt="" class="lox" :src="lox">
                      <p class="pxc">DrBody零动健康公众号</p>
                  </div>
                  <div class="outBox">
                      <img alt="" class="lox" :src="qux">
                      <p class="pxc">脊柱小助手@咨询</p>
                  </div>
              </div>
          </div>
      </div>

      <!-- page5 -->
      <div class="page5">
          <div class="title">
              <div class="left">{{byOrgName}}足部筛查团体报告</div>
              <img alt="logo" class="logo" :src="rlogo">
          </div>
          <div class="pie" ref="piefoo5"></div>
          <table border="1" style="width:100%;margin-top:40px" cellspacing="0" cellpadding="20">
              <tr class="tbfont"> 
                  <td style="width:20%"></td>
                  <td style="width:20%">正常</td>
                  <td style="width:20%">足部异常</td>
              </tr>
              <tr class="tbfont">
                  <td>男</td>
                  <td>{{manNormalfoo}}</td>
                  <td>{{manFlat+manHigh+manCongenital+manOther}}</td>
              </tr>
              <tr class="tbfont">
                  <td>女</td>
                  <td>{{womanNormalfoo}}</td>
                  <td>{{womanFlat+womanHigh+womanCongenital+womanOther}}</td>
              </tr>
          </table>
          <!-- chart -->
          <div class="barfoo5" ref="barfoo5"></div>
          <!-- footer -->
         <div class="footer" style="margin-top:10px !importtant">
            <span class="tex">零动医疗科技 www.zerodrbody.com</span>
            <div class="wxlogo">
                <div class="outBox" style="margin-right:68px">
                    <img alt="" class="lox" :src="lox">
                    <p class="pxc">DrBody零动健康公众号</p>
                </div>
                <div class="outBox">
                    <img alt="" class="lox" :src="qux">
                    <p class="pxc">脊柱小助手@咨询</p>
                </div>
                </div>
        </div>
      </div>
      <!-- page6 -->
      <div class="page6">
          <div class="title">
              <div class="left">{{byOrgName}}足部筛查团体报告</div>
              <img alt="logo" class="logo" :src="rlogo">
          </div>
          <div class="infoBox">
              <dl>
                  <dd style="color:#333333 !important;padding-bottom:50px;font-weight: bold">团体报告总结</dd>
                  <dd>1.足部检查正常人数{{manNormalfoo+womanNormalfoo}}（男生：{{manNormalfoo}}，女生{{womanNormalfoo}}），占总人数百分比{{(((manNormalfoo+womanNormalfoo)/(manNormalfoo+manFlat+manHigh+manCongenital+manOther+womanNormalfoo+womanFlat+womanHigh+womanCongenital+womanOther))*100).toFixed(2)}}%。</dd>
                  <dd>2.足部检查姿态异常人数{{manFlat+manHigh+manCongenital+manOther+womanFlat+womanHigh+womanCongenital+womanOther}}（男生：{{manFlat+manHigh+manCongenital+manOther}}，女生{{womanFlat+womanHigh+womanCongenital+womanOther}}），占总人数百分比{{(((manFlat+manHigh+manCongenital+manOther+womanFlat+womanHigh+womanCongenital+womanOther)/(manNormalfoo+manFlat+manHigh+manCongenital+manOther+womanNormalfoo+womanFlat+womanHigh+womanCongenital+womanOther))*100).toFixed(2)}}%。</dd>
              </dl>
              <dl style="margin-top:50px">
                  <dd style="color:#333333 !important;padding-bottom:10px;font-weight: bold">建议：</dd>
                  <dd>对于检测结果为足部异常的受检人，建议立即前往相关机构进行详细评估复诊确认，以便更好的了解自身足部健康情況。早发现早干预，一旦发现异常情况，请尽快前往相关机构进行干预矫正，在相关专业人员的建议下可使用矫形鞋垫进行矫正，以及配合相应的康复治疗，定期进行复查。</dd>
                  <dd>对于检测结果为正常的受检人，建议每4个月定期足部检查，了解受检人足部发育情况。</dd>
                  <dd style="margin-top:34px">建议受检组织普及足部健康知识、加大宣传教育力度，定期进行青少年足部健康相关讲座。</dd>
              </dl>
          </div>
          <!-- footer -->
          <div class="footer">
            <span class="tex">零动医疗科技 www.zerodrbody.com</span>
            <div class="wxlogo">
                <div class="outBox" style="margin-right:68px">
                    <img alt="" class="lox" :src="lox">
                    <p class="pxc">DrBody零动健康公众号</p>
                </div>
                <div class="outBox">
                    <img alt="" class="lox" :src="qux">
                    <p class="pxc">脊柱小助手@咨询</p>
                </div>
                </div>
         </div>
      </div>

  </div>
</template>
<script>
import htmlToPdf from "@/utils/htmlToPdf.js";
import {viewSpineOrgReport,viewFootOrgReport} from '@/api/orgController.js'
import {viewTaskInfo} from '@/api/checkTaskDetail.js'
import {imageUrl} from '@/utils/base64url.js'

export default {
    data(){
        return{
            imageUrl,
            id:null,//详情任务id
            //baseInfo
            orgName:null,
            orgCity:null,
            byOrgName:null,
            byOrgNo:null,
            //spineInfo
            spineInfo:null,
            spineTaltol:0,//脊柱总人数
            manNormal:0,//男正常
            manAnomalous:0,//男生-姿态异常
            manSeemingly:0,//男生-疑似侧弯
            manExamineException:0,//男生-检测异常
            womanNormal:0,//女正常
            womanAnomalous:0,//女生-姿态异常
            womanSeemingly:0,//女生-疑似侧弯
            womanExamineException:0,//女生-检测异常
            //脊柱占比
            nomalPer:0,//正常占比
            anomalousPer:0,//姿态异常占比
            seemingly:0,//疑似侧弯占比
            erroPer:0,//异常占比

            //footInfo
            manNormalfoo:0,//男-正常
            manFlat:0,//男-扁平
            manHigh:0,//男-高弓足
            manCongenital:0,//男-先天畸形
            manOther:0,//男-其他足部异常
            womanNormalfoo:0,
            womanFlat:0,
            womanHigh:0,
            womanCongenital:0,
            womanOther:0,

            //imgSrc
            rlogo:imageUrl.logo,
            //rlogo:'xx/logo.png',
             lox:imageUrl.codeL,
            //lox:'xxx/lox.png',
            qux:imageUrl.codeR,
            //qux:'xxx/qux.png'
        }
    },

    created(){
        if(this.$route.query.taskDetailId){
            this.id = this.$route.query.taskDetailId;
        }
    },
    mounted(){
        //getData
        this.getBaseInfo(this.id);
        this.getSpineData(this.id);//test --'1534954382317268994'
        this.getFootData(this.id);

       
    },

    methods:{
  
        //htmlToPdf.getPdf(this.byOrgName+'筛查团体报告');
 
        //getBaseInfo-data
        getBaseInfo(id){
            viewTaskInfo({
                id:id
            }).then(res=>{
                if(res.data.status==200){
                    this.orgName = res.data.data.orgName;
                    this.orgCity = res.data.data.provinceName + res.data.data.cityName;
                    this.byOrgName = res.data.data.examinedOrgName;
                    this.byOrgNo = res.data.data.taskDetailNo;
                }else{
                    this.$message.error(res.data.message);
                }
            }).catch(res=>{
                this.$message.error(res);
            })
        },
        //getData--spine
        getSpineData(id){
            viewSpineOrgReport({
                id:id
            }).then(res=>{
                if(res.data.status==200){
                    var toltal = res.data.data.manNormal+res.data.data.manAnomalous+res.data.data.manSeemingly+res.data.data.manExamineException + res.data.data.womanNormal+res.data.data.womanAnomalous+res.data.data.womanSeemingly+res.data.data.womanExamineException;
                    this.spineTaltol = toltal;
                    if(res.data.data.manNormal!=null){
                        this.manNormal = res.data.data.manNormal
                    }
                    if(res.data.data.manAnomalous!=null){
                        this.manAnomalous = res.data.data.manAnomalous
                    }
                    if(res.data.data.manSeemingly!=null){
                        this.manSeemingly = res.data.data.manSeemingly
                    }
                    if(res.data.data.manExamineException!=null){
                        this.manExamineException = res.data.data.manExamineException
                    }
                    //wonman
                    if(res.data.data.womanNormal!=null){
                        this.womanNormal = res.data.data.womanNormal 
                    }
                    if(res.data.data.womanAnomalous!=null){
                        this.womanAnomalous = res.data.data.womanAnomalous
                    }
                    if(res.data.data.womanSeemingly!=null){
                        this.womanSeemingly = res.data.data.womanSeemingly
                    }
                    if(res.data.data.womanExamineException!=null){
                        this.womanExamineException = res.data.data.womanExamineException
                    }

                this.nomalPer = ((res.data.data.manNormal+res.data.data.womanNormal)/(toltal)*100).toFixed(2);
                this.anomalousPer = ((res.data.data.manAnomalous+res.data.data.womanAnomalous)/(toltal)*100).toFixed(2);
                this.seemingly = ((res.data.data.manSeemingly+res.data.data.womanSeemingly)/(toltal)*100).toFixed(2);
                this.erroPer = ((res.data.data.manExamineException+res.data.data.womanExamineException)/(toltal)*100).toFixed(2);
                //init echart
                    this.barChart();
                    this.pieChart();
                    this.guaChart();
                    this.guaChartM();


                }else{
                    this.$message.error(res.data.message);
                }
            }).catch(res=>{
                this.$message.error(res);
            })
        },
        //getData--foot
        getFootData(id){
            viewFootOrgReport({
                id:id
            }).then(res=>{
                if(res.data.status==200){
                    this.footData = res.data.data;
                    //
                    if(res.data.data.manNormal!=null){
                        this.manNormalfoo = res.data.data.manNormal;
                    }
                    if(res.data.data.manFlat!=null){
                        this.manFlat = res.data.data.manFlat;
                    }
                    if(res.data.data.manHigh!=null){
                        this.manHigh = res.data.data.manHigh;
                    }
                    if(res.data.data.manCongenital!=null){
                        this.manCongenital = res.data.data.manCongenital;
                    }
                    if(res.data.data.manOther!=null){
                        this.manOther = res.data.data.manOther;
                    }
                
                    if(res.data.data.womanNormal!=null){
                        this.womanNormalfoo = res.data.data.womanNormal;
                    }
                    if(res.data.data.womanFlat!=null){
                        this.womanFlat = res.data.data.womanFlat;
                    }
                    if(res.data.data.womanHigh!=null){
                        this.womanHigh = res.data.data.womanHigh;
                    }
                    if(res.data.data.womanCongenital!=null){
                        this.womanCongenital = res.data.data.womanCongenital;
                    }
                    if(res.data.data.womanOther!=null){
                        this.womanOther = res.data.data.womanOther;
                    }
                    //
                    this.barChartfoo();
                    this.guaChartfoo();
                    this.pieChartfoo();
                    this.barChartfoo5();


                    //渲染完成后自动下载并退出
                    setTimeout(()=>{
                         htmlToPdf.getPdf(this.byOrgName+'筛查团体报告');
                         //this.$router.go(-1);
                    },1000)
                }else{
                    this.$message.error(res.data.message);
                }
            }).catch(res=>{
                this.$message.error(res);
            })
        },

        //chaert-methoeds
        barChart(){
        let bar1 = this.$echarts.init(this.$refs.bar);
        let option = {
             title:{
                    text:"总人数脊柱筛查占比数据图",
                    textStyle: {
                    fontSize: 28,
                    color:'#333333'
                },
                 },
            textStyle:{
                fontSize:'28',
                color:'#353535'
            },

            tooltip: {
                trigger: 'item',
                formatter:"{b}:{c}人",
            },
            legend: {
                top: '8%',
                left: 'left',
                itemWidth:60,
                itemHeight:26,
                textStyle: {
                  fontSize: 20
                },
                itemGap:70
            },
            series: [
                {
                type: 'pie',
                radius: ['40%', '66%'],
                center:['50%','64%'],
                avoidLabelOverlap: false,
                label: {
                    show: false,
                    position: 'center',
                    formatter:'{d}%' ,
                    labelLine :{show:true} ,
                    minMargin: 5,
                    edgeDistance: 10,
                    lineHeight: 15,
                    normal: {
                        textStyle: {
                                color: '#666666'  // 改变标示文字的颜色
                        },
                        formatter: ' {b}：{c}人 \n\n{d}%',
                    }
                },
               itemStyle:{
                   normal:{
                        color: function(params) {
                            // build a color map as your need.
                            var colorList = ['#00CCCC','#33A6FF','#FC5555','#FFE74E' ];
                                return colorList[params.dataIndex]
                            },
                        },
               },
                emphasis: {
                    label: {
                    show: true,
                    fontSize: '20',
                    fontWeight: 'bold'
                    }
                },
                labelLine: {
                    show: false,
                    normal:{  
                    length: 40,
                    length2: 80,
                    maxSurfaceAngle: 80,
                    lineStyle: {
                        //color: "red"  // 改变标示线的颜色
                        border:10,
                            }
                        },
                    },

                data: [  
                    { value: this.manNormal+this.womanNormal, name: '正常' },
                    { value: this.manAnomalous+this.womanAnomalous, name: '姿态异常' },
                    { value: this.manSeemingly + this.womanSeemingly, name: '疑似脊柱侧弯' },
                    { value: this.manExamineException+ this.womanExamineException, name: '检测异常' },
                ]
                }]
        };
         bar1.setOption(option);
        },
        //足部
        barChartfoo(){
        let bar1 = this.$echarts.init(this.$refs.barfoo);
        let option = {
             title:{
                    text:"总人数足部结果占比数据图",
                    textStyle: {
                    fontSize: 24,
                    color:'#333333'
                },
                 },
            textStyle:{
                fontSize:'22',
                color:'#353535'
            },

            tooltip: {
                trigger: 'item',
                formatter:"{b}:{c}人",
            },
            legend: {
                top: '18%',
                left: 'left',
               
                itemWidth:30,
                itemHeight:26,
                textStyle: {
                  fontSize: 20
                },
                itemGap:70
            },
            series: [
                {
                type: 'pie',
                radius: ['40%', '66%'],
                center:['52%','64%'],
                avoidLabelOverlap: false,
                label: {
                    show: false,
                    position: 'center',
                    formatter:'{d}%' ,
                    labelLine :{show:true} ,
                    minMargin: 5,
                    edgeDistance: 10,
                    lineHeight: 15,
                    normal: {
                        textStyle: {
                                color: '#666666'  // 改变标示文字的颜色
                        },
                        formatter: ' {b}：{c}人 \n\n{d}%',
                    }
                },
               itemStyle:{
                   normal:{
                        color: function(params) {
                            // build a color map as your need.
                            var colorList = ['#00CCCC','#33A6FF'];
                                return colorList[params.dataIndex]
                            },
                        },
               },
                emphasis: {
                    label: {
                    show: true,
                    fontSize: '20',
                    fontWeight: 'bold'
                    }
                },
                labelLine: {
                    show: false,
                    normal:{  
                    length: 20,
                    length2: 40,
                    maxSurfaceAngle: 80,
                    lineStyle: {
                        //color: "red"  // 改变标示线的颜色
                        border:10,
                            }
                        },
                    },

                data: [
                    { value: this.manNormalfoo+this.womanNormalfoo, name: '正常' },
                    { value: (this.manFlat+this.manHigh+this.manCongenital+this.manOther+this.womanFlat+this.womanHigh+this.womanCongenital+this.womanOther), name: '足部异常' },
                   
                ]
                }]
        };
         bar1.setOption(option);
        },
        //足部5
        barChartfoo5(){
        let bar1 = this.$echarts.init(this.$refs.barfoo5);
        let option = {
             title:{
                    text:"足部异常结果占比",
                    textStyle:{
                    fontSize: 28,
                    color:'#333333'
                },
                 },
            textStyle:{
                fontSize:'28',
                color:'#353535'
            },
            tooltip:{
                trigger: 'item',
                formatter:"{b}:{c}人",
            },
            legend: {
                top: '8%',
                left: 'left',
                itemWidth:60,
                itemHeight:26,
                textStyle: {
                  fontSize: 20
                },
                itemGap:70
            },
            series: [
                {
                type: 'pie',
                radius: ['40%', '66%'],
                center:['50%','64%'],
                avoidLabelOverlap: false,
                label: {
                    show: false,
                    position: 'center',
                    formatter:'{d}%' ,
                    labelLine :{show:true} ,
                    minMargin: 5,
                    edgeDistance: 10,
                    lineHeight: 15,
                    normal: {
                        textStyle: {
                                color: '#666666'  // 改变标示文字的颜色
                        },
                        formatter: ' {b}：{c}人 \n\n{d}%',
                    }
                },
               itemStyle:{
                   normal:{
                        color: function(params) {
                            //build a color map as your need.
                            var colorList = ['#00CCCC','#33A6FF','#FC5555','#FFE74E','#8080FF'];
                                return colorList[params.dataIndex]
                            },
                        },
               },
                emphasis: {
                    label: {
                    show: true,
                    fontSize: '20',
                    fontWeight: 'bold'
                    }
                },
                labelLine: {
                    show: false,
                    normal:{  
                    length: 40,
                    length2: 80,
                    maxSurfaceAngle: 80,
                    lineStyle: {
                        //color: "red"  // 改变标示线的颜色
                        border:10,
                            }
                        },
                    },
                data: [
                    { value: (this.manNormalfoo+this.womanNormalfoo), name: '正常' },
                    { value: (this.manFlat+this.womanFlat), name: '扁平足' },
                    { value: (this.manHigh+this.womanHigh), name: '高弓足' },
                    { value: (this.manCongenital+this.womanCongenital), name: '先天畸形' },
                    { value: (this.manOther+this.womanOther), name: '其它(需进一步筛查)'},
                ]
                }]
        };
         bar1.setOption(option);
        },
        //pie
        pieChart(){
        let pienew = this.$echarts.init(this.$refs.pie);
        let option ={
                 title: {
                        text: '男女脊柱筛查分类占比数据图',
                        textStyle: {
                            fontSize: 28,
                            color:'#333333'
                        }
                    },
                textStyle:{
                    fontSize:'28',
                    color:'#353535'
                    },
            tooltip: {
                trigger: 'axis',
                formatter: function(params) {
						var relVal = params[0].name;
						var value = 0;//总量
						for (var i = 0, l = params.length; i < l; i++) {
							value += params[i].value;
						};
						for (var i = 0, l = params.length; i < l; i++) {
							//marker 就是带颜色的小圆圈 seriesName图例名称；  value y轴坐标名称；
							relVal += '<br/>'+ params[i].marker + params[i].seriesName + parseFloat(params[i].value) +'人' +" "
                            +' 占比比例 : ' + (100 *parseFloat(params[i].value) / parseFloat(value)).toFixed(0) + "%";
						}
						return relVal;
					},
            },
            legend: {
                top: '10%',
                left: 'left',
                icon: "circle",
                itemWidth:50,
                itemHeight:20,
                itemGap:60,
            },
            grid: {
                left: '12%',
                top:'20%',
                right: '16%',
                bottom: '3%',
                containLabel: true
            },
            xAxis: {
                type: 'value',
                boundaryGap: [0, 0.6],
                axisLabel:{
                    show:true,
                     textStyle: {
                    fontSize : 28 ,   
                   },
                },
            },
            yAxis: {
                type: 'category',
                data: ['检测异常','疑似脊柱侧弯','姿态异常','正常'],
                axisLabel:{
                    show:true,
                     margin: 30,
                    textStyle: {
                    fontSize : 28 ,   
                   },
                },
                axisTick:{
                    show :false
                },
            },
            series: [
                {
                name: '男',
                type: 'bar',
                itemStyle:{
                   normal:{color:'#33A6FF' }
                },//ssss
                data: [this.manExamineException,this.manSeemingly,this.manAnomalous,this.manNormal],
                 label:{
                        show: true,
                        position: 'right',
                        formatter: '{c}人',
                    },

                },
                {
                name: '女',
                type: 'bar',
                data: [this.womanExamineException,this.womanSeemingly,this.womanAnomalous,this.womanNormal],
                itemStyle:{
                   normal:{color:'#00CCCC' }
                },
                 label:{
                        show: true,
                        position: 'right',
                        formatter: '{c}人',
                    }
                },
             ]
            };
            pienew.setOption(option);
        },
        //足部筛查
        pieChartfoo(){
        let pienew = this.$echarts.init(this.$refs.piefoo5);
        let option ={
                 title: {
                        text: '足部筛查结果男女占比图',
                        textStyle: {
                            fontSize: 28,
                            color:'#333333'
                        }
                    },
                textStyle:{
                    fontSize:'28',
                    color:'#353535'
                    },
            tooltip: {
                trigger: 'axis',
                formatter: function(params) {
						var relVal = params[0].name;
						var value = 0;//总量
						for (var i = 0, l = params.length; i < l; i++) {
							value += params[i].value;
						};
						for (var i = 0, l = params.length; i < l; i++) {
							//marker 就是带颜色的小圆圈 seriesName图例名称；  value y轴坐标名称；
							relVal += '<br/>'+ params[i].marker + params[i].seriesName + parseFloat(params[i].value) +'人' +" "
                            +' 占比比例 : ' + (100 *parseFloat(params[i].value) / parseFloat(value)).toFixed(0) + "%";
						}
						return relVal;
					},
            },
            legend: {
                top: '10%',
                left: 'left',
                icon: "circle",
                itemWidth:50,
                itemHeight:20,
                itemGap:60,
            },
            grid: {
                left: '12%',
                top:'20%',
                right: '16%',
                bottom: '3%',
                containLabel: true
            },
            xAxis: {
                type: 'value',
                boundaryGap: [0, 0.6],
                axisLabel:{
                    show:true,
                     textStyle: {
                    fontSize : 28 ,   
                   },
                },
            },
            yAxis: {
                type: 'category',
                data: ['足部异常','正常'],
                axisLabel:{
                    show:true,
                     margin: 30,
                    textStyle: {
                    fontSize : 28 ,   
                   },
                },
                axisTick:{
                    show :false
                },
            },
            series: [
                {
                name: '男',
                type: 'bar',
                itemStyle:{
                   normal:{color:'#33A6FF' }
                },
                data: [this.manFlat+this.manHigh+this.manCongenital+this.manOther,this.manNormalfoo],
                 label:{
                        show: true,
                        position: 'right',
                        formatter: '{c}人',
                    },

                },
                {
                name: '女',
                type: 'bar',
                data: [this.womanFlat+this.womanHigh+this.womanCongenital+this.womanOther,this.womanNormalfoo],
                itemStyle:{
                   normal:{color:'#00CCCC' }
                },
                 label:{
                        show: true,
                        position: 'right',
                        formatter: '{c}人',
                    }
                },
             ]
            };
            pienew.setOption(option);
        },
    //guage charts
    guaChart(){
        let guageChart = this.$echarts.init(this.$refs.guage);
        const guageData = [
            {
                value: (((this.manAnomalous+this.womanAnomalous)/this.spineTaltol)*100).toFixed(2),
                name: '整体',
                title: {
                    fontSize:'24',
                    offsetCenter: ['0%', '-44%']
                },
                detail: {
                    fontSize:'24',
                    valueAnimation: true,
                    offsetCenter: ['0%', '-28%']
                }
            },
            {
                value: (((this.manAnomalous)/this.spineTaltol)*100).toFixed(2),
                name: '男生',
                title: {
                    fontSize:'24',
                    offsetCenter: ['0%', '-4%']
                },
                detail: {
                valueAnimation: true,
                offsetCenter: ['0%', '12%']
                }
            },
            {
                value: (((this.womanAnomalous)/this.spineTaltol)*100).toFixed(2),
                name: '女生',
                title: {
                offsetCenter: ['0%', '36%'],
                fontSize:'24'
                }, 
                detail: {
                fontSize:'24',
                valueAnimation: true,
                offsetCenter: ['0%', '52%']
                }
            }
            ];
           let options = {
               title: {
                        text: '姿态异常评估分析',
                        textStyle: {
                            fontSize: 28,
                            color:'#333333'
                        }
                    },
                series: [
                    {
                    type: 'gauge',
                    startAngle: 90,
                    endAngle: -270,
                    pointer: {
                        show: false
                    },
                    itemStyle:{
                        normal:{
                                color: function(params) {
                                 // build a color map as your need.
                                var colorList = ['#FC5555','#41A9F9','#00CCCC'];
                                return colorList[params.dataIndex]
                             },
                         },
                    },
                    progress: {
                        show: true,
                        overlap: false,
                        roundCap: true,
                        clip: false,
                        itemStyle: {
                        borderWidth: 1,
                        borderColor: '#464646'
                        }
                    },
                    axisLine: {
                        lineStyle: {
                        width: 60
                        }
                    },
                    splitLine: {
                        show: false,
                        distance: 0,
                        length: 10
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        show: false,
                        distance: 50
                    },
                    data: guageData,
                    detail: {
                        width: 80,
                        height: 28,
                        fontSize: 28,
                        color: 'auto',
                        borderColor: 'auto',
                        borderRadius: 16,
                        borderWidth: 1,
                        formatter: '{value}%'
                    }
                    }
                ]
                };
                guageChart.setOption(options);
        },
        //足部
        guaChartfoo(){
        let guageChart = this.$echarts.init(this.$refs.guagefoo);
        const guageData = [
            {
                value: (((this.manFlat+this.manHigh+this.manCongenital+this.manOther+this.womanFlat+this.womanHigh+this.womanCongenital+this.womanOther)/(this.manNormalfoo+this.manFlat+this.manHigh+this.manCongenital+this.manOther+this.womanNormalfoo+this.womanFlat+this.womanHigh+this.womanCongenital+this.womanOther))*100).toFixed(2),
                name: '整体',
                title: {
                    fontSize:'24',
                    offsetCenter: ['0%', '-44%']
                },
                detail: {
                    fontSize:'24',
                    valueAnimation: true,
                    offsetCenter: ['0%', '-28%']
                }
            },
            {
                value: (((this.manFlat+this.manHigh+this.manCongenital+this.manOther)/(this.manNormalfoo+this.manFlat+this.manHigh+this.manCongenital+this.manOther+this.womanNormalfoo+this.womanFlat+this.womanHigh+this.womanCongenital+this.womanOther))*100).toFixed(2),
                name: '男生',
                title: {
                    fontSize:'24',
                    offsetCenter: ['0%', '-4%']
                },
                detail: {
                fontSize:'24',
                valueAnimation: true,
                offsetCenter: ['0%', '12%']
                }
            },
            {
                value: (((this.womanFlat+this.womanHigh+this.womanCongenital+this.womanOther)/(this.manNormalfoo+this.manFlat+this.manHigh+this.manCongenital+this.manOther+this.womanNormalfoo+this.womanFlat+this.womanHigh+this.womanCongenital+this.womanOther))*100).toFixed(2),
                name: '女生',
                title: {
                offsetCenter: ['0%', '36%'],
                fontSize:'24'
                }, 
                detail: {
                fontSize:'24',
                valueAnimation: true,
                offsetCenter: ['0%', '52%']
                }
            }
            ];
           let options = {
               title: {
                        text: '足部异常男女评估分析',
                        textStyle: {
                            fontSize: 28,
                            color:'#333333'
                        }
                    },
                series: [
                    {
                    type: 'gauge',
                    startAngle: 90,
                    endAngle: -270,
                    pointer: {
                        show: false
                    },
                    itemStyle:{
                        normal:{
                                color: function(params) {
                                 // build a color map as your need.
                                var colorList = ['#FC5555','#41A9F9','#00CCCC'];
                                return colorList[params.dataIndex]
                             },
                         },
                    },
                    progress: {
                        show: true,
                        overlap: false,
                        roundCap: true,
                        clip: false,
                        itemStyle: {
                        borderWidth: 1,
                        borderColor: '#464646'
                        }
                    },
                    axisLine: {
                        lineStyle: {
                        width: 60
                        }
                    },
                    splitLine: {
                        show: false,
                        distance: 0,
                        length: 10
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        show: false,
                        distance: 50
                    },
                    data: guageData,
                    detail: {
                        width: 80,
                        height: 28,
                        fontSize: 28,
                        color: 'auto',
                        borderColor: 'auto',
                        borderRadius: 16,
                        borderWidth: 1,
                        formatter: '{value}%'
                    }
                    }
                ]
                };
                guageChart.setOption(options);
        },
        //man chart
        guaChartM(){
        let guageChartM = this.$echarts.init(this.$refs.guageM);
        const guageData = [
            {
                value: (((this.manSeemingly+this.womanSeemingly)/this.spineTaltol)*100).toFixed(2),
                name: '整体',
                title: {
                    fontSize:'24',
                    offsetCenter: ['0%', '-44%']
                },
                detail: {
                    fontSize:'24',
                    valueAnimation: true,
                    offsetCenter: ['0%', '-28%']
                }
            },
            {
                value: (((this.manSeemingly)/this.spineTaltol)*100).toFixed(2),
                name: '男生',
                title: {
                    fontSize:'24',
                    offsetCenter: ['0%', '-4%']
                },
                detail: {
                fontSize:'24',
                valueAnimation: true,
                offsetCenter: ['0%', '12%']
                }
            },
            {
                value: (((this.womanSeemingly)/this.spineTaltol)*100).toFixed(2),
                name: '女生',
                title: {
                offsetCenter: ['0%', '36%'],
                fontSize:'24'
                }, 
                detail: {
                    fontSize:'24',
                    valueAnimation: true,
                    offsetCenter: ['0%', '52%']
                }
            }
            ];
           let options = {
               title: {
                        text: '疑似脊柱侧弯评估分析',
                        textStyle: {
                            fontSize: 28,
                            color:'#333333'
                        }
                    },
                series: [
                    {
                    type: 'gauge',
                    startAngle: 90,
                    endAngle: -270,
                    pointer: {
                        show: false
                    },
                    itemStyle:{
                        normal:{
                                color: function(params) {
                                 // build a color map as your need.
                                var colorList = ['#FC5555','#41A9F9','#00CCCC'];
                                return colorList[params.dataIndex]
                             },
                         },
                    },
                    progress: {
                        show: true,
                        overlap: false,
                        roundCap: true,
                        clip: false,
                        itemStyle: {
                        borderWidth: 1,
                        borderColor: '#464646'
                        }
                    },
                    axisLine: {
                        lineStyle: {
                        width: 60
                        }
                    },
                    splitLine: {
                        show: false,
                        distance: 0,
                        length: 10
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        show: false,
                        distance: 50
                    },
                    data: guageData,
                    detail: {
                        width: 80,
                        height: 28,
                        fontSize: 28,
                        color: 'auto',
                        borderColor: 'auto',
                        borderRadius: 16,
                        borderWidth: 1,
                        formatter: '{value}%'
                    }
                    }
                ]
                };
                guageChartM.setOption(options);
    }
 }     
}
</script>

<style lang="stylus">
//commont
.tbfontt
    font-family: Source Han Sans CN
    font-size: 25px
    color: #666666
    text-align: left
.tbfont
    font-family: Source Han Sans CN
    font-size: 28px
    color: #666666
    text-align: center
.footer
    display: flex
    margin-top: 110px
    justify-content: space-between
    align-items: flex-end
    width: 100%
    .tex 
        font-size: 28px
        font-family:Source Han Sans CN
        color: #666666
    .wxlogo
        display: flex
        .outBox
            text-align: center
            .lox 
                width: 160px
                height: 160px
            .pxc
                font-family: Source Han Sans CN
                font-size: 20px
                color: #666666
                margin: 0 !important
//nolmal style
.downloadReport
    width: 1480px
    margin: 0 auto
    padding: 40px
    overflow hidden
    .page1
        width: 100%
        overflow hidden
        .title 
            display: flex
            justify-content: space-between
            align-items: center
            .left
                font-family: Source Han Sans CN
                font-size: 42px
                color: #333333
                font-weight: bold
            .logo 
                width: 298px
                height: 48px
        .baseInfo
            width: 100%
            margin-top: 64px
            .top2
                font-family: Source Han Sans CN
                font-weight:bold
                font-size: 28px
                color: #333333
        .bar 
            width: 100%
            margin-top: 64px
            height:860px
        
    
    .page2
        width: 100%
        margin-top: 84px
        overflow hidden
        .title 
            display: flex
            justify-content: space-between
            align-items: center
            .left
                font-family: Source Han Sans CN
                font-size: 42px
                color: #333333
                font-weight: bold
            .logo 
                width: 298px
                height: 48px
        .pie
            width: 100%
            margin-top: 64px
            height:710px
        .thchart
            width: 100%
            overflow hidden
            margin-top: 100px
            display: flex
            justify-content: space-between
            align-items: center
            .chartBox
                width: 648px 
                text-align: center
                .gauge
                    width: 100%
                    margin-left: 24px
                    margin-top: 14px
                    height:648px
            .tableBox
                width: 744px

    .page3
        width: 100%
        margin-top: 100px
        overflow hidden
        .title 
            display: flex
            justify-content: space-between
            align-items: center
            .left
                font-family: Source Han Sans CN
                font-size: 42px
                color: #333333
                font-weight: bold
            .logo 
                width: 298px
                height: 48px
        .pie
            width: 100%
            margin-top: 64px
            height:710px
        .thchart
            width: 100%
            overflow hidden
            margin-top: 100px
            display: flex
            justify-content: space-between
            align-items: center
            .chartBox
                width: 648px 
                text-align: center
                .gauge
                    width: 100%
                    margin-left: 24px
                    margin-top: 14px
                    height:648px
            .tableBox
                width: 744px
        .infoBox
            margin-top: 120px
            dl 
                color:#666666
                font-family: SourceHanSansCN-Regular
                font-size:28px
                line-height: 52px
    .page4
        width: 100%
        margin-top: 40px
        overflow hidden
        width: 100%
        overflow hidden
        .title 
            display: flex
            justify-content: space-between
            align-items: center
            .left
                font-family: Source Han Sans CN
                font-size: 42px
                color: #333333
                font-weight: bold
            .logo 
                width: 298px
                height: 48px
        .baseInfo
            width: 100%
            margin-top: 64px
            .top2
                font-family: Source Han Sans CN
                font-weight:bold
                font-size: 28px
                color: #333333
        .chartTable4
            display: flex
            align-items: center
            justify-content: space-between
            .tablebox4
                width: 620px
            .bar4 
                width:866px
                margin-top: 74px
                height:600px
        .thchartfoo
            width: 100%
            overflow hidden
            margin-top: 100px
            display: flex
            align-items: center
            .chartBox
                width: 648px 
                text-align: center
                .gauge
                    width: 100%
                    margin-left: 24px
                    margin-top: 14px
                    height:648px
            .tableBox
                width:600px
                margin-left: 220px
    .page5
        width: 100%
        margin-top: 60px
        overflow hidden
        width: 100%
        overflow hidden
        .title 
            display: flex
            justify-content: space-between
            align-items: center
            .left
                font-family: Source Han Sans CN
                font-size: 42px
                color: #333333
                font-weight: bold
            .logo 
                width: 298px
                height: 48px
        .pie
            width: 100%
            margin-top: 64px
            height:540px
        .barfoo5
            width: 100%
            margin-top: 74px
            height:860px
    
    .page6
        width: 100%
        margin-top: 140px
        overflow hidden
        .title 
            display: flex
            justify-content: space-between
            align-items: center
            .left
                font-family: Source Han Sans CN
                font-size: 42px
                color: #333333
                font-weight: bold
            .logo 
                width: 298px
                height: 48px
        .pie
            width: 100%
            margin-top: 64px
            height:710px
        .infoBox
            margin-top: 120px
            dl 
                color:#666666
                font-family: SourceHanSansCN-Regular
                font-size:28px
                line-height: 52px
    


                
            





</style>